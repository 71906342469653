$font-family: "proxima-nova";

@mixin basic-modal{
    background: #252525 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 0px 50px #00000080;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    height: 910px;
    width: 652px;
    padding: 50px 87px !important;
    margin-top: 10%;

    .modal-dialog{
        max-width: unset;
        margin-left: 0px !important;
    }
    .modal-content{
        background: unset;
        border: unset;
    }
}
@mixin form-btn {
    box-shadow: 0px 0px 10px #00000033;
    border-radius: 10px;
    font-family: $font-family;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.75px;
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    width: 190px;
    height: 60px;
    margin-top: 14px;
  }
#configurationListModal{
    @include basic-modal;
    .has-search {
        width: 100%;
        margin-bottom: 0;
        position: relative;
        .form-control {
            padding-left: 2.375rem;
        }
        .form-control-feedback {
            position: absolute;
            z-index: 2;
            display: block;
            width: 2.375rem;
            height: 2.375rem;
            line-height: 3rem;
            text-align: center;
            pointer-events: none;
            color: #aaa;
        }
        .close-icon{
            color: #aaaaaa;
            position: absolute;
            z-index: 2;
            display: block;
            width: 2.375rem;
            height: 2.375rem;
            line-height: 3rem;
            color: #aaa;
            right: 0;
            top: 0;
        }
    }
    .btn {
        @include form-btn;
    }
    .modal-header,.modal-footer{
        justify-content: center;
        border: unset;
        .modal-title{
            font-family: "proxima-nova";
            font-size: 50px;
            line-height: 61px;
            font-weight: bold;
            letter-spacing: 0px;
            width: 100%;
            margin-top: 16px;
        }
    }
    .breadcrumb-item+.breadcrumb-item::before {
        color: white;
        content: "|";
    }
    .breadcrumb{
        background: unset;
        justify-content: flex-end;
        a{
            color: white;
            font-family: $font-family;
        }
        .breadcrumb-item.active{
            color: white;
        }
    }
    .form-control{
        padding-left: 1rem;
        height: 50px;
        font-family: $font-family;
        font-size: 15px;
        letter-spacing: 0.38px;
        color: #000000;
     }
     .scrollit{
         max-height: 540px;
         overflow: hidden;
         margin-bottom: 10px;
         padding-right:2px;
         margin-right: 15px;
         cursor: pointer;
         &:hover{
             overflow-y: auto;
         }
     }
     .table-max{
        max-height: 540px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 2px;
      }
     .table{
         background-color: #252525;
         text-align: left;
         font-family: $font-family;
         margin-bottom: 0px !important;
         th {
             border: none;
         }
         .tbody {
             height: 100px;
             overflow-y: auto;
             overflow-x: hidden;
         }
         .no-records-alert{
             color: #E72729;
             font-family: $font-family;
             font-size: 16px;
         }
     }
}

.config-list-modal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
        margin-left: -500px;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
}



