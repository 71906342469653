#sample-canvas {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  height: 100%;
  outline: none;
  vertical-align: top;
  width: 100%;
}

.fixed-box {
  position: absolute;
  width: 100%;
  height: 90%;
  background: transparent;
  color: transparent;
}

.canvas-position {
  margin-bottom: 10px;
  margin-top: 30px;
  max-height: 250px;
  min-height: 100px;
}
