$font-family: "proxima-nova";

@mixin basic-modal{
    width: 1100px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 87px !important;
    margin-top: 10% !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
                padding: 0;
                background-color: transparent;
                border: 0;
                opacity: 1;
                color: $bg-red;
                text-shadow: none;
                &:focus{
                    outline: unset;
                    opacity: 1;
                }
        }
        .modal-title{
            margin: 0px 0px 24px 0px;
            font-family: $font-family;
            font-size: 50px;
            line-height: 61px;
            font-weight: bold;
            letter-spacing: 0px;
        }

    }
}

#preCalLightIncludeModal{
    @include basic-modal;
    width: auto;
    padding: 0!important;
    margin: 30px 475px 0 25px !important;
    @media screen and (max-width: $large-three) {
        margin: 15px 25px!important;
    }
    .vehicle-side{
        justify-content: center;
        display: flex;
        margin-bottom: 121px;
        p{
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0px;
            text-transform: uppercase;
            font-weight: lighter;
        }
    }
    .modal-content{
        .close-icon {
            position: absolute;
            top: 30px; 
            right: 30px;   
        }
        .modal-title{
           padding: 25px 40px 15px;
           margin: 0;
           font-size: 32px;
           line-height: 36px;
        }
        .sub-details {
            p{
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0;
                font-weight: unset;
            }
          }
    }
}
#preCalLightIncludeModal{
    .precal-light-include-content{
        max-height: calc(100vh - 245px);
        overflow-y: scroll;
        padding: 10px 40px 0px;
        .sticky {
            background: #121212;
        }
    }
    .zone-light-section{
        background: #121212 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 5px #00000080;
        border-radius: 20px;
        padding: 10px 15px;
    }
    .zone-info{
        flex: 1 1 480px;
        padding: 0px 1.5rem;
        margin-bottom: 25px;
        width: 50%;
    }
    .btn{
        @include form-btn;
        margin-right: 15px;
    }
    .action-btn {
        justify-content: end;
        padding: 0 25px 15px;
        display: flex;
    }
    .zone-max-height {
        height: 475px;
    }
}
.preCal-modal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
}

