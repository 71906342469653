// styles for the change password
.changepass-title h2 {
  font-family: "proxima-nova";
  font-size: 50px;
  line-height: 61px;
  font-weight: bold;
  letter-spacing: 0px;
  width: 100%;
  margin-top: 16px;
}
.changepassword-modal {
  .modal-content{
    display: initial !important;
    background-color: transparent !important;
  }
  .modal-dialog {
    max-width: unset;
    margin: 0 auto;
    width: 615px;
  }
}
.changepassword {
  width: 800px;
  height: fit-content;
  background: #252525 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #00000080;
  border-radius: 50px;
  padding: 50px 87px !important;
  margin-top: 10%;
  margin-left: -15%;
  .modal-dialog {
    max-width: unset;
  }
  .modal-content {
    background: unset;
    border: unset;
    display: initial !important;
    background-color: transparent !important;
    .close {
      padding: 0;
      background-color: transparent;
      border: 0;
      opacity: 1;
      &:focus {
        outline: unset;
        opacity: 1;
      }
    }
    .changepass-title {
      position: relative;
      margin: 1rem 0 2rem;
      h2 {
        font-family: $font-family-base;
        font-size: 35px;
        line-height: 40px;
        letter-spacing: 1.25px;
        color: #ffffff;
        width: 100%;
      }
    }
    .desc {
      font-family: $font-family-base;
      font-size: 16px;
      color: white;
      text-align: left;
    }
  }
}

// .modal-content{
//   display: initial !important;
//   background-color: transparent !important;
// }
.login-trouble-link {
  margin-top: 30px;
  .btn {
    font-family: $font-family-base;
    font-size: 14px;
    letter-spacing: 0px;
  }
}

@mixin login-home-formlabel {
  font-family: $font-family-base;
  font-size: 15px;
  line-height: 18px;
  font-weight: medium;
  letter-spacing: 0.75px;
  color: white;
  text-transform: uppercase;
}
@mixin login-home-forminput {
  border-radius: 5px;
  height: 31px;
  // margin-bottom: 25px;
}
@mixin form-btn {
  box-shadow: 0px 0px 10px #00000033;
  border-radius: 10px;
  font-family: $font-family-base;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.75px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
  width: 190px;
  height: 60px;
  margin-top: 14px;
}
@mixin all-form-inputs {
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000040;
  border-radius: 5px;
}
/* custom select */
@mixin custom-select {
  select.input-lg {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }
  .form-group {
    position: relative;
  }
  .form-control {
    width: 183px;
    height: 50px;
    font-family: $font-family-base;
    font-size: 15px;
    letter-spacing: 0.38px;
    text-transform: uppercase;
  }
  select + img {
    position: absolute;
    bottom: 18px;
    margin-left: 150px;
    pointer-events: none;
    // background-color: #fff;
    padding-right: 5px;
  }
}

.logintrouble-block,
.changepass-block {
  .troublelogin-form,
  .changepass-form {
    label {
      @include login-home-formlabel;
    }
    input {
      @include login-home-forminput;
    }
    .btn {
      @include form-btn;
    }
  }
}
.img-profile {
  height: 30px;
}

.cursor-auto {
  cursor: auto !important;
}

.user-input {
  background: white !important;
  color: black !important;
  border: red;
  border-radius: 5px;
  height: 31px;
}
.user-fg{
  margin-bottom: 0 !important;
  height: 85px;
}
.leading-none {
  line-height: 1 !important;
}

.btn-red {
  background: #E72729;
}
.btn-black {
    background: #000000;
}

.__react_component_tooltip {
  max-width: 300px !important;
}
.__react_component_tooltip.show {
  opacity: 1 !important;
}

.trouble-login-modal {
  .modal-dialog {
    max-width: unset;
    margin: 0 auto;
    width: 615px;
    .modal-content{
      background: transparent;
      // margin-left: -15%;
    }
  }
}

.zone-sidebar-list {
  .__react_component_tooltip {
    max-width: 200px !important;
  }
}

.check-height {
  height: 20px;
}