$font-family: "proxima-nova";

@mixin basic-modal{
    width: 1200px;
    max-height: 920px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 87px !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
                padding: 0;
                background-color: transparent;
                border: 0;
                opacity: 1;
                color: $bg-red;
                text-shadow: none;
                &:focus{
                    outline: unset;
                    opacity: 1;
                }
        }
        .modal-title{
            margin-top: 16px;
            font-family: $font-family;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0px;
        }

    }
}

#pdfPopupModal{
    @include basic-modal;
    .btn{
        @include form-btn;
    }
}
.pdf-popup-modal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
}
