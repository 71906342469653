.warning-selection-checkbox {
    min-width: 650px;

    span {
        vertical-align: middle;
        top: -5px;
        position: relative;
    }

    input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}