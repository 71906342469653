$font-family: "proxima-nova";

@mixin basic-modal{
    width: 800px;
    max-height: 800px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 87px !important;
    margin-top: 10% !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
                padding: 0;
                background-color: transparent;
                border: 0;
                opacity: 1;
                color: $bg-red;
                text-shadow: none;
                &:focus{
                    outline: unset;
                    opacity: 1;
                }
        }
        .modal-title{
            margin-top: 16px;
            font-family: $font-family;
            font-size: 50px;
            line-height: 61px;
            font-weight: bold;
            letter-spacing: 0px;
        }

    }
}

#preCalModal{
    @include basic-modal;
    .vehicle-side{
        justify-content: center;
        display: flex;
        margin-bottom: 121px;
        p{
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0px;
            text-transform: uppercase;
            font-weight: lighter;
        }
    }
}
#preCalModal{
    .rotation-option{
        justify-content: center;
        display: flex;
        label{
            @include login-home-formlabel;
            font-weight: 400;
        }
        margin-bottom: 46px !important;
    }
    .table-dark{
        table-layout: fixed !important;
        width: 625px !important;
        max-height: 200px !important;
        display: block;
        background: #252525 0% 0% no-repeat padding-box !important;
    }
    .table td, .table th, .table tr{
       border: none;
       background: #252525 0% 0% no-repeat padding-box !important;
       vertical-align: middle;
       padding: 1rem;
    }

    .btn{
        @include form-btn;
    }
}
.preCal-modal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
        margin-left: -25%;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
}
