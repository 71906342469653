$font-family: "proxima-nova";

#obstructModal p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0px;
    margin-right: 1rem;
    font-weight: unset;
    color: white;
  }

  #obstructModal .obstruct-img {
    margin: 30px;
  }

  #obstructModal .obstruct-zone {
    margin-bottom: 60px;
    margin-top: 35px;

  }

  #obstructModal .obstruct-zone P {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: white;
  }
  #obstructModal .btn {
    -webkit-box-shadow: 0px 0px 10px #00000033;
            box-shadow: 0px 0px 10px #00000033;
    border-radius: 10px;
    font-family: "proxima-nova";
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.75px;
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    width: 190px;
    height: 60px;
    margin-top: 14px;
  }

  @media screen and (max-width: 1366px) {
    #obstructModal .btn {
      width: 180px;
      height: 50px;
    }
  }
.abstruct-modal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
        margin-left: -27%;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
}
.obstarctlight{
    width: 810px;
    max-height: 800px;
    background: #252525 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 0px 50px #00000080;
            box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 87px !important;
    margin-top: 3% !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
                padding: 0;
                background-color: transparent;
                border: 0;
                opacity: 1;
                color: $bg-red;
                text-shadow: none;
                &:focus{
                    outline: unset;
                    opacity: 1;
                }
        }
        .modal-title{
            margin-top: 16px;
            font-family: $font-family;
            font-size: 50px;
            line-height: 61px;
            font-weight: bold;
            letter-spacing: 0px;
        }
        .check-height {
          height: 30px;
        }
    }

}
