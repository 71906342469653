$font-family: "proxima-nova";

@mixin basic-modal{
    width: 775px;
    max-height: 800px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 87px !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
                padding: 0;
                background-color: transparent;
                border: 0;
                opacity: 1;
                color: $bg-red;
                text-shadow: none;
                &:focus{
                    outline: unset;
                    opacity: 1;
                }
        }
        .modal-title{
            margin-top: 16px;
            font-family: $font-family;
            font-size: 50px;
            line-height: 61px;
            font-weight: bold;
            letter-spacing: 0px;
        }

    }
}
@mixin current-selection{
    p{
        margin-top: 10px;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0px;
        margin-right: 1rem;
        font-weight: unset;
        color: white;
    }
}
#rotationModal{
    @include basic-modal;
    @include current-selection;
    .vehicle-side{
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
        p{
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-weight: lighter;
        }
    }
}
#rotationModal{
    .rotation-option{
        justify-content: center;
        display: flex;
        label{
            @include login-home-formlabel;
            font-weight: 400;
        }
        @include custom-select;
       select{
           width: 275px !important;
       }
       select + img {
        margin-left: 245px;
    }
        margin-bottom: 46px !important;
    }
    .table-dark{
        table-layout: fixed !important;
        width: 600px !important;
        max-height: 200px !important;
        overflow-y: scroll;
        display: block;
        background: #252525 0% 0% no-repeat padding-box !important;
    }
    .table td, .table th, .table tr{
       border: none;
       background: #252525 0% 0% no-repeat padding-box !important;
    }

    .btn{
        @include form-btn;
    }
}
.rotation-modal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
        margin-left: -27%;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
}
.headlight-rotation{
    position: relative;
 }
.barB{
    position: absolute;
    right: 50%;
    left: 8px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 52px;
}
.headB{
    position: absolute;
    right: 50%;
    left: 8px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 17px;
}
.headC{
    position: absolute;
    left: 272px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 54px;
}
.headD{
    position: absolute;
    right: 50%;
    left: 8px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 92px;
}
.headA{
    position: absolute;
    left: 0;
    right: 238px;
    margin-left: auto;
    margin-right: auto;
    top: 54px;
}
.mg-top-3{
    margin-top: 3% !important ;
}
.mg-top-4{
    margin-top: 4% !important;
}