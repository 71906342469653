$font-family: "proxima-nova";

@mixin basic-modal{
    width: 775px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 87px !important;
    margin-top: 1% !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
                padding: 0;
                background-color: transparent;
                border: 0;
                opacity: 1;
                color: $bg-red;
                text-shadow: none;
                &:focus{
                    outline: unset;
                    opacity: 1;
                }
        }
        .modal-title{
            margin-top: 16px;
            font-family: $font-family;
            font-size: 50px;
            line-height: 61px;
            font-weight: bold;
            letter-spacing: 0px;
        }

    }
}
@mixin login-home-formlabel {
  font-family: $font-family;
  font-size: 15px;
  line-height: 18px;
  font-weight: medium;
  letter-spacing: 0.75px;
  color: white;
  text-transform: uppercase;
}
@mixin form-btn {
  box-shadow: 0px 0px 10px #00000033;
  border-radius: 10px;
  font-family: $font-family;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.75px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
  width: 190px;
  height: 60px;
  margin-top: 14px;
}
/* custom select */
@mixin custom-select {
  select.input-lg {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }
  .form-group {
    position: relative;
  }
  .form-control {
    width: 183px;
    height: 35px;
    font-family: $font-family;
    font-size: 15px;
    letter-spacing: 0.38px;
    // text-transform: uppercase;
  }
  select + img {
    position: absolute;
    bottom: 6px;
    margin-left: 150px;
    pointer-events: none;
    background-color: unset;
    padding-right: 5px;
  }
}
#ConfigOpt{
    @include basic-modal;
    max-height: 900px;
}
#ConfigOpt{
  .modal-title{
    margin-bottom: 18px;
  }
  .config-form{
    @include login-home-formlabel;
    @include custom-select;
    .form-control{
        width: 275px;
    }
    select + img {
        margin-left: 245px;
    }
    .btn{
        @include form-btn;
    }
  }
  .sm-input-file{
      border: 1px solid #333;
      background: #ccc;
      color: #777;
      opacity:0;
      width: 20px !important;
      display: none;
  }
  .span-text{
    display: contents;
    color: #fff;
    position: absolute;
  }
  .file-name {
    height: 30px;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .img-close-btn {
    padding-top: 12px !important;
  }
}
.configdetail-modal {
    .modal-dialog{
        max-width: unset;
        margin-left: -27%;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
}
