@keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%;
    }

    100%,
    60% {
      left: 100%;
      right: -90%;
    }
  }

  @keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }

    100%,
    60% {
      left: 107%;
      right: -8%;
    }
  }

  .progress {
    position: relative;
  }

  .progress-xs,
  .progress-xs .progress-bar {
    height: 0.25rem;
  }

  .progress-sm,
  .progress-sm .progress-bar {
    height: 0.5rem;
  }

  .progress-bar-indeterminate {
    &:after,
    &:before {
      content: "";
      position: absolute;
      background-color: inherit;
      left: 0;
      will-change: left, right;
      top: 0;
      bottom: 0;
    }

    &:before {
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
        infinite;
    }

    &:after {
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
        infinite;
      animation-delay: 1.15s;
    }
  }

  @keyframes loader {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
/**
Dimmer
*/
.dimmer {
    position: relative;

    .loader {
      display: none;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }

    &.active {
      .loader {
        display: block;
      }

      .dimmer-content {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .loader {
    display: block;
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
    color: $blue;

    &:before,
    &:after {
      width: 2.5rem;
      height: 2.5rem;
      margin: (-2.5rem / 2) 0 0 (-2.5rem / 2);
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
    }

    &:before {
      border-radius: 50%;
      border: 3px solid currentColor;
      opacity: 0.15;
    }

    &:after {
      animation: loader 0.6s linear;
      animation-iteration-count: infinite;

      border-radius: 50%;
      border: 3px solid;
      border-color: transparent;
      border-top-color: #d63939;
      box-shadow: 0 0 0 1px transparent;
    }
  }


  .smallloader {
    display: block;
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    color: $blue;

    &:before,
    &:after {
      width: 1.5rem;
      height: 1.5rem;
      margin: (-1.5rem / 2) 0 0 (-2.5rem / 2);
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
    }

    &:before {
      border-radius: 50%;
      border: 3px solid currentColor;
      opacity: 0.15;
    }

    &:after {
      animation: loader 0.6s linear;
      animation-iteration-count: infinite;

      border-radius: 50%;
      border: 3px solid;
      border-color: transparent;
      border-top-color: #d63939;
      box-shadow: 0 0 0 1px transparent;
    }
  }

  .fullloader {
    display: contents;
    position: unset;
    height: 5.5rem;
    width: 5.5rem;
    color: $blue;

    &:before,
    &:after {
      width: 5.5rem;
      height: 5.5rem;
      margin: (-2.5rem / 2) 0 0 (-2.5rem / 2);
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
    }

    &:before {
      border-radius: 50%;
      border: 5px solid currentColor;
      opacity: 0.15;
    }

    &:after {
      animation: loader 0.6s linear;
      animation-iteration-count: infinite;

      border-radius: 50%;
      border: 5px solid;
      border-color: transparent;
      border-top-color: #d63939;
      box-shadow: 0 0 0 1px transparent;
    }
  }

