@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap");

// Variables
@import "variables";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

// Whelen UI
@import "./style.scss";

// change password
@import "pages/change_password";

// Add Light
@import "pages/add_light";

// Session timeout pop up
@import "pages/session_modal";

// Zone page
@import "pages/zone_page";
@import "./pages/obstract_model";
@import "./pages/rotation_model";
@import "./pages/config_details";
@import "./pages/save_as_config_modal";
@import "./pages/configlist_modal";
@import "./pages/precal_warn_model";
@import "./pages/pdf_popup_model";
@import "./pages/Calculation_Warning_modal";
@import "./pages/lightbar_preview_model";
@import "./pages/precal_light_include";

// Font Icon
@import "../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import url("https://use.typekit.net/apm0zsy.css");
