// Options
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

$transition-base: 0.5s all;
$enable-dark-mode: true !default;

//Fonts
$font-family-base: "proxima-nova";
$font-family-monospace: "proxima-nova";

$font-family-secondary: "proxima-nova";

$font-size-base: 0.9375rem;
$font-size-lg: 1.125rem;
$font-size-sm: 0.875rem;

$light: 300;
$regular: 400;
$medium: 500;
$semi: 600;

$line-height-base: 1.5;

$h0-font-size: 3.13rem; //50px
$h1-font-size: 2rem; //32px
$h2-font-size: 1.75rem; //28px
$h3-font-size: 1.5rem; //24px
$h4-font-size: 1.125rem; //18px
$h5-font-size: 1rem; //16px
$h6-font-size: 0.875rem; //14px

$small-font-size: 87.5%;

$headings-margin-bottom: 0.66em;
$headings-font-family: inherit;
$headings-font-weight: 600;
$headings-line-height: 1.1;
$headings-color: inherit;

$display1-size: 4.5rem;
$display2-size: 4rem;
$display3-size: 3.5rem;
$display4-size: 3rem;

// Colors
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #343a40;
$gray-700: #495057;
$gray-800: #252729;
$gray-900: #1f1f1f;

$light: #f4f6fa !default;
$dark: #1f1f1f !default;

$body-bg: $light !default;
$body-color: $dark !default;

$blue: #206bc4 !default;
$azure: #4299e1 !default;
$indigo: #4263eb !default;
$purple: #72558f !default;
$pink: #d6336c !default;
$red: #d63939 !default;
$orange: #f76707 !default;
$yellow: #f59f00 !default;
$lime: #74b816 !default;
$green: #2fb344 !default;
$teal: #0ca678 !default;
$cyan: #17a2b8 !default;
$black: #000000 !default;
$white: #ffffff !default;

$text-muted: #9aa0ac;
$text-muted-dark: #6e7687;
$text-muted-opacity: 0.5;

$light: #f4f6fa !default;

$primary: $red;
$secondary: $gray-600;
$success: $green;
$info: $azure;
$warning: $yellow;
$danger: $red;
$light: $gray-100;

$colors: (
    "blue": $blue,
    "azure": $azure,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "lime": $lime,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
);

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
);

$social-colors: (
    "facebook": #3b5998,
    "twitter": #1da1f2,
    "google": #dc4e41,
    "youtube": #f00,
    "vimeo": #1ab7ea,
    "dribbble": #ea4c89,
    "github": #181717,
    "instagram": #e4405f,
    "pinterest": #bd081c,
    "vk": #6383a8,
    "rss": #ffa500,
    "flickr": #0063dc,
    "bitbucket": #0052cc,
);

$body-bg: #f5f7fb;
$body-color: $gray-700;

// Code
$code-font-size: 85%;
$code-color: inherit;

$kbd-color: #fff;
$kbd-bg: $gray-800;

$pre-color: $gray-900;

// Yiq
$yiq-contrasted-threshold: 190;
$yiq-text-dark: $body-color;
$yiq-text-light: $white;

// Buttons
$input-line-height: (24/15);
$btn-line-height: (24/13);

$input-line-height-sm: (16/14);
$btn-line-height-sm: (16/12);

$input-line-height-lg: (26/18);
$btn-line-height-lg: (26/16);

$input-btn-focus-width: 2px;

$input-disabled-bg: $gray-100;

$input-focus-border-color: #1991eb;
$custom-select-focus-border-color: #1991eb;

// Borders
$border-width: 1px;
//$border-color: #dee3eb;
$border-color: rgba(0, 40, 100, 0.12);
$border-color-dark: rgba(0, 40, 100, 0.24);

// Inputs
$input-bg: #fff;
$input-height: 2.375rem;

$input-color: $gray-700;
$input-border-color: $border-color;
$input-border-width: 1px;
$input-box-shadow: inset 0 1px 1px rgba(#000, 0.075);

$input-group-addon-border-color: $input-border-color;
$input-group-addon-bg: #fbfbfc;

$input-placeholder-color: $gray-500;

// Grid
$grid-columns: 12;
$grid-gutter-width: 1.5rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1280px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px,
);

$speed: 3; //second
$part-second: 0.25; //second
$speed: $speed + $part-second * 4;
$animation-timing: ease-in-out;

// Header
$header-bg: #0667d0;
$header-color: #fff;
$header-height: 4.5rem;

// Cards
$card-border-color: $border-color;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

// Alerts
$alert-link-font-weight: 600;

// Spacing
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer * 0.75,
    4: $spacer * 1,
    5: $spacer * 1.5,
    6: $spacer * 2,
    7: $spacer * 3,
    8: $spacer * 4,
    9: $spacer * 6,
);

$sizes: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer * 0.75,
    4: $spacer * 1,
    5: $spacer * 1.5,
    6: $spacer * 2,
    7: $spacer * 3,
    8: $spacer * 4,
    9: $spacer * 6,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
);

$vh: 100vh;
$vw: 100vw;

// Footer
$footer-bg: #fff;

// Dropdowns
$dropdown-border-color: $border-color;
$dropdown-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

// Z-index
$zindex-overlay: 900;
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// Components
$border-radius: 3px;
$border-radius-lg: 3px;
$border-radius-sm: 3px;

// Tables
$table-accent-bg: rgba(0, 0, 0, 0.02);
$table-hover-bg: rgba(0, 0, 0, 0.04);

$custom-select-padding-y: 0.5rem;
$custom-select-padding-x: 0.75rem;

$custom-select-indicator-color: #999;
$custom-select-indicator: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E");

// Aside
$aside-width: 22rem;

// List group
$list-group-border-color: $border-color;
$list-group-active-color: $primary;
$list-group-active-bg: mix($primary, #fff, 4%);
$list-group-active-border-color: $list-group-border-color;

// Popovers
$popover-body-padding-y: 0.75rem;
$popover-body-padding-x: 1rem;
$popover-border-color: #dee3eb;
$popover-body-color: $text-muted-dark;
$popover-arrow-width: 0.5rem;
$popover-arrow-height: 0.5rem;

// Badges
$badge-font-weight: 600;

// Hr
$hr-border-color: $border-color;

// Thumbnails
$thumbnail-bg: #fff;

// Pagination
$pagination-color: $body-color;
$pagination-disabled-color: $gray-400;

// Navs
$nav-tabs-link-active-bg: transparent;

@mixin bg-variant($parent, $color) {
    #{$parent} {
        background-color: $color !important;
    }
    a#{$parent},
    button#{$parent} {
        @include hover-focus {
            background-color: darken($color, 10%) !important;
        }
    }
}

// Dark mode
$dark-mode-dark: darken($dark, 1%) !default;
$dark-mode-darken: darken($dark, 2%) !default;
$dark-mode-darkest: darken($dark, 3%) !default;
$dark-mode-light: lighten($gray-800, 1%) !default;
$dark-mode-lighten: lighten($gray-800, 2%) !default;
$dark-mode-lightest: lighten($gray-800, 3%) !default;

$dark-mode-text: $light;

$light-black: rgba($dark, 0.24) !default;
$light-mix: rgba(mix($light, $dark, 64%), 0.24) !default;
$light-white: rgba($light, 0.24) !default;

$extra-large-one: 1600px;
$extra-large-two: 1440px;
$large-one:1366px;
$large-two:1200px;
$large-three:1080px;
