@mixin form-btn {
    box-shadow: 0px 0px 10px #00000033;
    border-radius: 10px;
    font-family: $font-family;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.75px;
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    width: 190px;
    height: 60px;
    margin-top: 14px;
}

#session-modal {
    width: 430px;
    max-height: 550px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 57px !important;
    margin-top: 10% !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
          padding: 0;
          background-color: transparent;
          border: 0;
          opacity: 1;
          color: $bg-red;
          text-shadow: none;
          &:focus{
              outline: unset;
              opacity: 1;
          }
        }
        .modal-title{
          font-family: $font-family;
          font-size: 25px;
          font-weight: bold;
          letter-spacing: 0px;
        }
    }

  }

  #session-modal{
    .notification{
        display: flex;
        label{
            @include login-home-formlabel;
            font-weight: 400;
        }
        margin-bottom: 10px !important;
    }

    .btn{
        @include form-btn;
    }
  }

  .sessionModal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
        margin-left: 72%;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
  }